import React, { useState } from 'react';
import './App.css';

function Header({ darkMode, toggleDarkMode }) {
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: darkMode ? '#333333' : '#ffffff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const logoStyle = {
    maxHeight: '50px',
    margin: 0,
  };

  const textStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0 0 0 10px',
    textDecoration: 'none',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const iconStyle = {
    cursor: 'pointer', // Make the icon clickable
    marginLeft: 'auto',
  };

  

  return (
    <header style={{ ...headerStyle, ...iconStyle }} >
      <div>
        <a href="/">
          <img src="logo800.png" alt="Company Logo" style={logoStyle} />
        </a>
      </div>

      <div style={textStyle}>
        <a href="/" style={{ textDecoration: 'none', color: textStyle.color }}>
          CollegeAdmits
        </a>
      </div>

      <div style={iconStyle}onClick={toggleDarkMode}>
        {darkMode ? '☀️' : '🌙'}
      </div>
    </header>
  );
}


function Footer({ darkMode, toggleDarkMode }) {
  const footerStyle = {
    backgroundColor: darkMode ? '#333333' : '#004aad',
    padding: '30px',
    textAlign: 'center',
    fontSize: '16px',
    position: 'relative',
    bottom: '0',
  };

  const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    marginLeft: '10px',
    marginRight: '10px',
  };

  const copyrightStyle = {
    marginTop: '20px',
    color: '#fff',
  };

  return (
    <div style={footerStyle}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href="/terms" style={linkStyle}>
          Terms of Use
        </a>

        <span style={{ display: 'inline-block', marginLeft: '10px', marginRight: '10px', color: '#fff' }}>
          |
        </span>

        <a href="/privacy" style={linkStyle}>
          Privacy Policy
        </a>
      </div>

      <div style={copyrightStyle}>
        © 2024 CollegeAdmits. All Rights Reserved.
      </div>
    </div>
  );
}

function Terms() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`page-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="content-wrap">
        <Header darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />

        <div style={{ margin: '0 auto', maxWidth: '1000px', paddingLeft: '20px', paddingRight: '20px' }}>
          <h1 style={{ textAlign: 'left' }}>Terms of Use</h1>
          <p style={{ textAlign: 'left' }}>
            Welcome to CollegeAdmits! By accessing or using our website, https://www.collegeadmits.org, you agree to be bound by these Terms of Use.
            Throughout these Terms of Use, "we" or "our" or "us" refers to CollegeAdmits and "you" or "your" refers to the user or visitor of our website, https://www.collegeadmits.org.
            If you do not agree to these Terms of Use, please do not use our website.
          </p>

          <h2 style={{ textAlign: 'left' }}>1. Ownership of Content</h2>
          <p style={{ textAlign: 'left' }}>
            All content on our website, including but not limited to text, graphics, images, and software, is owned by or licensed to CollegeAdmits and is protected by
            copyright laws. You may not modify, reproduce, distribute, or display any content from our website without our prior written consent.
          </p>

          <h2 style={{ textAlign: 'left' }}>2. User Conduct</h2>
          <p style={{ textAlign: 'left' }}>
            You agree to use our website only for lawful purposes and in a manner that does not infringe on the rights of others. You may not use our
            website to upload, post, or transmit any content that is unlawful, threatening, abusive, defamatory, or otherwise objectionable.
          </p>

          <h2 style={{ textAlign: 'left' }}>3. Disclaimer of Warranties</h2>
          <p style={{ textAlign: 'left' }}>
            We provide our website on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, about
            the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related
            graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
          </p>

          <h2 style={{ textAlign: 'left' }}>4. Limitation of Liability</h2>
          <p style={{ textAlign: 'left' }}>
            In no event shall CollegeAdmits be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss
            or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of our website.
          </p>

          <h2 style={{ textAlign: 'left' }}>5. Links to Third-Party Websites</h2>
          <p style={{ textAlign: 'left' }}>
            Our website may contain links to third-party websites. We are not responsible for the content or reliability of any third-party websites, and we do not
            endorse any products or services offered on such websites.
          </p>

          <h2 style={{ textAlign: 'left' }}>6. Indemnification</h2>
          <p style={{ textAlign: 'left' }}>
            You agree to indemnify, defend, and hold CollegeAdmits and its affiliates, officers, directors, employees, and agents harmless from and against any
            claims, liabilities, damages, losses, costs, or expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use
            of our website or any violation of these Terms of Use.
          </p>

          <h2 style={{ textAlign: 'left' }}>7. Termination</h2>
          <p style={{ textAlign: 'left' }}>
            We reserve the right to terminate or suspend your access to our website at any time, without notice, for any reason, including but not limited to a
            breach of these Terms of Use.
          </p>

          <h2 style={{ textAlign: 'left' }}>8. Governing Law</h2>
          <p style={{ textAlign: 'left' }}>
            These Terms of Use shall be governed by and construed in accordance with the laws of the United States.
          </p>

          <h2 style={{ textAlign: 'left' }}>9. Changes to These Terms of Use</h2>
          <p style={{ textAlign: 'left' }}>
            We reserve the right to update or modify these Terms of Use at any time, without notice to you. Your continued use of our website after any changes to
            these Terms of Use constitutes your acceptance of the updated Terms of Use.
          </p>

          <h2 style={{ textAlign: 'left' }}>10. Contact Us</h2>
          <p style={{ textAlign: 'left' }}>
            If you have any questions or concerns about these Terms of Use, please contact us.
          </p>

          <div className="terms-spacer"></div>
        </div>

        <Footer darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      </div>
    </div>
  );
}

export default Terms;
