import React from 'react';
import './Card.css';

const cardData = [

  { title: 'College Applications Essay Outline Generator', content: 'Generate an outline for your college applications essay to assist in your brainstorming process.', url: '/essay-outline' },
  { title: 'Common App Essay Outline Generator', content: 'Create an outline for your Common App essays to organize your thoughts and structure your ideas clearly.', url: '/commonapp-prompts' },
  { title: 'College Research Tool', content: 'Explore and compare colleges and get information about acceptance rates, average admitted GPA and test scores, popular majors, and more.', url: '/college-research' },


  { title: 'College List Generator', content: 'Generate a list of colleges to apply to based on your intended major, location, GPA, and test scores.', url: '/college-list' },
  { title: 'College Major Identifier', content: 'Identify potential college majors based on your interests, academic strengths, and dream job.', url: '/college-major' },
  { title: 'Extracurricular Activities List Generator', content: 'Create a list of extracurricular activities to enhance your college applications.', url: '/extracurricular' },
  { title: 'Cover Letter Generator', content: 'Create a custom cover letter in seconds with our easy-to-use cover letter generator.', url: '/cover-letter' },

  { title: 'Resume Bullet Point Builder', content: 'Craft powerful bullet points for your resume that showcase your skills and accomplishments.', url: '/resume' },
  { title: 'Interview Prep', content: 'Ace your job interview with personalized responses to practice interview questions.', url: '/interview-prep' },
  { title: 'Networking Message', content: 'Perfect for anyone looking to connect with industry professionals and grow their network.', url: '/networking-message' },
  { title: 'Recruiter Message', content: 'Get noticed by recruiters to help increase your chances of landing an interview.', url: '/recruiter-message' },

  { title: 'LinkedIn Headline Generator', content: 'Generate a powerful headline that showcases your expertise and sets you apart in your field.', url: '/linkedin-headline' },
  { title: 'LinkedIn Summary Generator', content: 'Craft a compelling LinkedIn summary that highlights your professional experience and skills.', url: '/linkedin-summary' },
  { title: 'LinkedIn Skills Generator', content: 'Stay competitive in your industry with a list of in-demand skills to take your career to the next level.', url: '/linkedin-skills' },
  { title: 'LinkedIn Post Generator', content: 'Create engaging posts, showcasing your accomplishments to your network.', url: '/linkedin-post' },

  { title: 'Elevator Pitch Generator', content: 'Improve your networking and stand out from the competition with a compelling elevator pitch.', url: '/elevator-pitch' },
  { title: 'Career Finder', content: 'Explore potential career opportunities that match your interests and strengths.', url: '/career-finder' },
  { title: 'Email Writer', content: 'Write professional emails quickly and efficiently with our AI-powered email writer.', url: '/email' },
  { title: 'Ask Me Anything', content: 'Expand your knowledge with the power of AI that effortlessly answers your questions with ease.', url: '/ask-me-anything' },

];

function Card(props) {
  return (
    <div className="card">
      <h2>{props.title}</h2>
      <p>{props.content}</p>
      <a href={props.url} target="_blank" rel="noopener noreferrer" className="button">Go to tool</a>
    </div>
  );
}

function Toolscards() {
  return (
    <div className="card-container">
      {cardData.map((card) => (
        <Card title={card.title} content={card.content} url={card.url} key={card.title} />
      ))}
    </div>
  );
}

export default Toolscards;
