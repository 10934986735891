import React, { useEffect, useState } from 'react';
import { Configuration, OpenAIApi } from 'openai';
import './App.css';


function Header({ darkMode, toggleDarkMode }) {
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: darkMode ? '#333333' : '#ffffff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const logoStyle = {
    maxHeight: '50px',
    margin: 0,
  };

  const textStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0 0 0 10px',
    textDecoration: 'none',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const iconStyle = {
    cursor: 'pointer', // Make the icon clickable
    marginLeft: 'auto',
  };

  

  return (
    <header style={{ ...headerStyle, ...iconStyle }} >
      <div>
        <a href="/">
          <img src="logo800.png" alt="Company Logo" style={logoStyle} />
        </a>
      </div>

      <div style={textStyle}>
        <a href="/" style={{ textDecoration: 'none', color: textStyle.color }}>
          CollegeAdmits
        </a>
      </div>

      <div style={iconStyle}onClick={toggleDarkMode}>
        {darkMode ? '☀️' : '🌙'}
      </div>
    </header>
  );
}



function Footer({ darkMode, toggleDarkMode }) {
  const footerStyle = {
    backgroundColor: darkMode ? '#333333' : '#004aad',
    padding: '30px',
    textAlign: 'center',
    fontSize: '16px',
    position: 'relative',
    bottom: '0',
    
  };

  const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    marginLeft: '10px',
    marginRight: '10px',
  };

  const copyrightStyle = {
    marginTop: '20px',
    color: '#fff',
  }

  return (
    <div style={footerStyle}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <a href="/terms" style={linkStyle}>
          Terms of Use
        </a>

        <span style={{ display: 'inline-block', marginLeft: '10px', marginRight: '10px', color: '#fff'}}>
          |
        </span>

        <a href="/privacy" style={linkStyle}>
          Privacy Policy
        </a>
      </div>

      <div style={copyrightStyle}>
        © 2024 CollegeAdmits. All Rights Reserved.
      </div>
    </div>
  );
}



function Interviewprep() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false); // Added dark mode state

  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAPI_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const onChange = (e) => {
    setQuestion(e.target.value);
  };

  const onClick = async () => {
    if (!question) {
      alert("Please enter an interview question and a brief summary of your past work experience.");
      return;
    }

    setLoading(true);
    const response = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages: [
        {"role": "system", "content": "I answer interview questions. Ask me an interview question and I will give you a response."},
        {"role": "user", "content": question},
      ],
      temperature: 0.7,
      max_tokens: 550,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });

    setLoading(false);
    setAnswer(response.data.choices[0].message.content);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`page-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="content-wrap">

        <Header darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />

        <h1>Interview Prep</h1>
        <p>Enter an interview question and a brief summary of your past work experience </p>
        <textarea
          onChange={onChange}
          value={question}
          rows={4}
          cols={50}
          placeholder={`What are your greatest strengths? Financial Analyst for 5 years`}
          maxLength={1200}
          required
        />

        <button onClick={onClick}>Submit</button>

        <div className="container">
          {loading ? (
            <p className='answer'>CollegeAdmits is thinking ... </p>
          ) : (
            <p className='answer'>{answer}</p>
          )}
        </div>

        <div className="terms-spacer"></div>

      </div>

      <Footer darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />

    </div>
  );
}


export default Interviewprep;
