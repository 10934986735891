import React, { useState } from 'react';
import './App.css';

function Header({ darkMode, toggleDarkMode }) {
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: darkMode ? '#333333' : '#ffffff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const logoStyle = {
    maxHeight: '50px',
    margin: 0,
  };

  const textStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0 0 0 10px',
    textDecoration: 'none',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const iconStyle = {
    cursor: 'pointer', // Make the icon clickable
    marginLeft: 'auto',
  };

  

  return (
    <header style={{ ...headerStyle, ...iconStyle }} >
      <div>
        <a href="/">
          <img src="logo800.png" alt="Company Logo" style={logoStyle} />
        </a>
      </div>

      <div style={textStyle}>
        <a href="/" style={{ textDecoration: 'none', color: textStyle.color }}>
          CollegeAdmits
        </a>
      </div>

      <div style={iconStyle}onClick={toggleDarkMode}>
        {darkMode ? '☀️' : '🌙'}
      </div>
    </header>
  );
}


function Footer({ darkMode, toggleDarkMode }) {
  const footerStyle = {
    backgroundColor: darkMode ? '#333333' : '#004aad',
    padding: '30px',
    textAlign: 'center',
    fontSize: '16px',
    position: 'relative',
    bottom: '0',
  };

  const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    marginLeft: '10px',
    marginRight: '10px',
  };

  const copyrightStyle = {
    marginTop: '20px',
    color: '#fff',
  };

  return (
    <div style={footerStyle}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href="/terms" style={linkStyle}>
          Terms of Use
        </a>

        <span style={{ display: 'inline-block', marginLeft: '10px', marginRight: '10px', color: '#fff' }}>
          |
        </span>

        <a href="/privacy" style={linkStyle}>
          Privacy Policy
        </a>
      </div>

      <div style={copyrightStyle}>
        © 2024 CollegeAdmits. All Rights Reserved.
      </div>
    </div>
  );
}

function Privacy() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`page-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="content-wrap">
        <Header darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />

        <div style={{ margin: '0 auto', maxWidth: '1000px', paddingLeft: '20px', paddingRight: '20px' }}>
          <h1 style={{ textAlign: 'left' }}>Privacy Policy</h1>
          <p style={{ textAlign: 'left' }}>
            At CollegeAdmits, we take your privacy very seriously and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website https://www.collegeadmits.org or any related services.
          </p>

          <h2 style={{ textAlign: 'left' }}>1. Collection of Personal Information</h2>
          <p style={{ textAlign: 'left' }}>
            When you use our website, we may collect certain personal information from you, such as your name, email address, phone number, and any other information you provide to us. We may also collect non-personal information, such as your IP address and browser type.
          </p>

          <h2 style={{ textAlign: 'left' }}>2. Use of Personal Information</h2>
          <p style={{ textAlign: 'left' }}>
            We may use your personal information to provide you with our services, to communicate with you, and to improve our website. We may also use your personal information to send you promotional materials or other communications that we think may be of interest to you.
          </p>

          <h2 style={{ textAlign: 'left' }}>3. Disclosure of Personal Information</h2>
          <p style={{ textAlign: 'left' }}>
            We will not sell, rent, or share your personal information with any third parties without your consent, except as required by law or as necessary to provide our services.
          </p>

          <h2 style={{ textAlign: 'left' }}>4. Security of Personal Information</h2>
          <p style={{ textAlign: 'left' }}>
            We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the security of your personal information.
          </p>

          <h2 style={{ textAlign: 'left' }}>5. Cookies and Tracking Technologies</h2>
          <p style={{ textAlign: 'left' }}>
            We may use cookies and other tracking technologies to collect information about your use of our website and to personalize your experience. You can choose to disable cookies in your browser settings, but this may affect your ability to use certain features of our website.
          </p>

          <h2 style={{ textAlign: 'left' }}>6. Links to Third-Party Websites</h2>
          <p style={{ textAlign: 'left' }}>
            Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites, and we encourage you to review their privacy policies before providing any personal information.
          </p>

          <h2 style={{ textAlign: 'left' }}>7. Changes to This Privacy Policy</h2>
          <p style={{ textAlign: 'left' }}>
            We may update this Privacy Policy from time to time, and we encourage you to review it periodically. Your continued use of our website after any changes to this Privacy Policy constitutes your acceptance of the updated Privacy Policy.
          </p>

          <h2 style={{ textAlign: 'left' }}>8. Contact Us</h2>
          <p style={{ textAlign: 'left' }}>
            If you have any questions or concerns about this Privacy Policy or our use of your personal information, please contact us.
          </p>
          <div className="terms-spacer"></div>

        </div>

        <Footer darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      </div>
    </div>
  );
}

export default Privacy;
